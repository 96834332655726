import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './App.css';

function InvoicesList() {
  const [invoices, setInvoices] = useState([]);

  useEffect(() => {
    const fetchInvoices = async () => {
      const endpoints = [
        'https://ciz.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://martas.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://ottocene.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://tuttobenegrazie.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://elita.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://martas-farini.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://fa-bio.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://lomdopolavoro.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://dada.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://zelda.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://trinca.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://bicchierino.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://parea-mare.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
        'https://masayume.gotaac.it/garda21_pos_restaurant_ws/invoices_status',
      ];

      const invoicesResponse = await axios.all(endpoints.map(endpoint => axios.post(endpoint, {})));

      const combinedInvoices = invoicesResponse.flatMap(({ data }, index) =>
        data.result.map(invoice => ({ ...invoice, source: endpoints[index] }))
      );

      // Group invoices by source
      const groupedInvoices = combinedInvoices.reduce((acc, invoice) => {
        const source = invoice.source;
        if (!acc[source]) {
          acc[source] = [];
        }
        acc[source].push(invoice);
        return acc;
      }, {});

      setInvoices(groupedInvoices);
      console.log(groupedInvoices);
    };

    fetchInvoices().catch(error => {
      console.error(error);
    });
  }, []);

  const getFatturapaStateIcon = state => {
    switch (state) {
      case 'sent':
        return <div className="circle green"></div>;
      case 'error':
        return <div className="circle red"></div>;
      default:
        return <div className="circle orange"></div>;
    }
  };

  return (
    <div className="container">
      <h1>Dashboard fatturazione</h1>
      {Object.entries(invoices).map(([source, invoices]) => (
        <div key={source}>
          <h2>{new URL(source).hostname.split('.')[0]}</h2>
          <table>
            <thead>
              <tr>
                <th>Fattura</th>
                <th>Data</th>
                <th>Cliente</th>
                <th>Totale</th>
                <th>Imponibile</th>
                <th>IVA</th>
                <th>Stato</th>
                <th>Stato IVA</th>
              </tr>
            </thead>
            <tbody>
              {invoices.map(invoice => (
                <tr key={invoice.source + invoice.id}>
                  <td>{invoice.number}</td>
                  <td>{invoice.date}</td>
                  <td>{invoice.partner_name}</td>
                  <td>{invoice.amount_total.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</td>
                  {invoice.amount_untaxed ? (<>
                    <td>{invoice.amount_untaxed.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</td>
                    <td>{invoice.amount_by_group[0][1].toLocaleString('it-IT', { style: 'currency', currency: 'EUR' })}</td>
                  </>) : (<>
                    <td></td>
                    <td></td>
                  </>)}
                  <td>{getFatturapaStateIcon(invoice.fatturapa_state)} {invoice.fatturapa_state}</td>
                  {invoice.amount_untaxed ? (<>
                    <td>{(invoice.amount_by_group[0][1] / invoice.amount_untaxed * 100).toFixed(2)}%</td>
                  </>) : (
                    <td></td>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </div>
  );
}

export default InvoicesList;
